import type { HTMLReactParserOptions } from 'html-react-parser'
import parse, { domToReact, Element } from 'html-react-parser'
import NextHead from 'next/head'

import Favicon from './Favicon'
import MetaList from './MetaList'
import Title from './Title'

interface HeadProps {
  filename: string | undefined
  path: string | undefined
  frontmatter: any | undefined
  configHead: string | undefined
  configTitle: string | undefined
  configTitleTemplate: string | undefined
  configFaviconHref: string | undefined
  configBaseCanonicalUrl: string | undefined
}

const Head = ({
  filename,
  path,
  frontmatter,
  configHead,
  configTitle,
  configTitleTemplate,
  configFaviconHref,
  configBaseCanonicalUrl,
}: HeadProps): JSX.Element => {
  // The following are tags we control, and need to merge e.g.
  // with frontmatter `meta` data. So we find them in the
  // config DOM head, extract the necessary attributes, and remove
  // them from the config DOM head. For <meta> tags, we enforce
  // a strict control over tags to prevent crashing in React. For
  // instance, if you include `\\'` inside a content prop, it will
  // break the parser and result in a prop that is exploded into
  // pieces that don't adhere to the spec. For instance "acme`s docs
  // site" will become
  // {"name":"description","content":"acme\\","s":"","docs":"","site":"" }
  // And this will cause the render to fail.
  let configHeadTitle: string | undefined = undefined
  const configHeadMetaPropertyTags: { property: string; content: string }[] = []
  const configHeadMetaNameTags: { name: string; content: string }[] = []
  let hasViewportMeta = false
  let configHeadFaviconHref: string | undefined = undefined

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.type === 'tag') {
        if (domNode.name === 'title') {
          configHeadTitle = domToReact(domNode.children, options) as string
          // Remove title from head
          return <></>
        } else if (
          domNode.name === 'meta' &&
          domNode.attribs?.property &&
          domNode.attribs?.content
        ) {
          configHeadMetaPropertyTags.push({
            ...domNode.attribs,
            property: domNode.attribs?.property,
            content: domNode.attribs?.content,
          })
          // Remove from head and keep control
          return <></>
        } else if (
          domNode.name === 'meta' &&
          domNode.attribs?.name &&
          domNode.attribs?.content
        ) {
          configHeadMetaNameTags.push({
            ...domNode.attribs,
            name: domNode.attribs?.name,
            content: domNode.attribs?.content,
          })
          // Remove from head and keep control
          return <></>
        } else if (
          domNode.name === 'meta' &&
          domNode.attribs?.name === 'viewport'
        ) {
          // Keep in head, just set a flag to not add our
          // own default viewport tag
          hasViewportMeta = true
        } else if (
          domNode.name === 'link' &&
          (domNode.attribs?.rel === 'shortcut icon' ||
            domNode.attribs?.rel === 'icon')
        ) {
          configHeadFaviconHref = domNode.attribs?.href
          // Remove favicon from config head
          return <></>
        }
      }
    },
  }

  // Return the parsed config head, but without the special
  // elements such as <title>, <meta> or <script> as they
  // will be handled manually.
  const parsedHead = parse(configHead || '', options) as JSX.Element

  return (
    <>
      {/* IMPORTANT: title, meta and other elements need to be direct
      children of next/head, otherwise they won't be properly picked
      up on the client-side. So to ensure this, we defer to the
      components themselves to wrap the content in next/head */}
      <Title
        filename={filename}
        frontmatter={frontmatter}
        configTitle={configTitle}
        configHeadTitle={configHeadTitle}
        configTitleTemplate={configTitleTemplate}
      />
      <MetaList
        frontmatter={frontmatter}
        configHeadMetaPropertyTags={configHeadMetaPropertyTags}
        configHeadMetaNameTags={configHeadMetaNameTags}
      />
      <Favicon
        frontmatter={frontmatter}
        configFaviconHref={configFaviconHref}
        configHeadFaviconHref={configHeadFaviconHref}
      />
      <NextHead>
        {parsedHead}
        {/* If no viewport meta is specified in the config head,
        add one ourselves */}
        {!hasViewportMeta && (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        )}
        {configBaseCanonicalUrl && (
          <link rel="canonical" href={`${configBaseCanonicalUrl}${path}`} />
        )}
      </NextHead>
    </>
  )
}

export default Head
